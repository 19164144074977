@import "../vars";
.contacts{
  padding: 70px 0 77px;
  &__wrap{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
  &__group{
    max-width: 310px;
  }
  &__title{
    line-height: 121%;
    margin-bottom: 25px;
  }
  &__item{
    display: flex;
    align-items: center;
    &:not(:last-child){
      margin-bottom: 17px;
    }
  }
  &__icon{
    width: 27px;
    flex-shrink: 0;
    margin-right: 12px;
    img{
      width: 100%;
      display: block;
    }
  }
  &__phone{
    font-size: 22px;
    line-height: 150%;
    font-weight: 700;
    display: block;
  }
  &__mail{
    display: block;
  }
}

.map{
  margin-top: 60px;
  height: 388px;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 992px){
  .contacts{
    padding: 30px 0 40px;
    &__wrap{
      grid-template-columns: 1fr 1fr;
    }
    &__title{
      margin-bottom: 10px;
    }
    &__item{
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
    &__icon{
      width: 22px;
      margin-right: 8px;
    }
    &__phone{
      font-size: 18px;
    }
  }

  .map{
    margin-top: 30px;
  }
}


@media screen and (max-width: 576px){
  .contacts{
    padding-bottom: 0;
    &__wrap{
      grid-template-columns: 1fr;
      gap: 30px;
    }
    &__group{
      max-width: 100%;
    }
  }

  .map{
    width: calc(100% + 40px);
    margin-left: -20px;
    border-radius: 0;
  }
}
