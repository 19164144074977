@import 'vars';
@import 'components/_text.scss';
@import 'components/_buttons.scss';
@import 'components/_fonts.scss';
.swiper{
    &-button{
        &-next,
        &-prev{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.mbtn-blue{
                opacity: 1;
                border: 2px solid $primary;
                &.swiper-button-disabled{
                    background-color: transparent;
                    path{
                        fill:$black;
                    }
                }
            }

        }
    }
}
.fg {
    position: relative;
    margin: 0 0 23px;
    input {
        border: $border;
        padding: 0 22px;
        height: 50px;
        width: 100%;
        border-radius: 10px;
        font-size: 15px;
        background-color: $grayBg;
        color: #000;
        &::placeholder {
            color: #A0A0A0;
        }
    }
    label{
        display: block;
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 161.8%;
        text-transform: uppercase;
    }
}

.check {
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        cursor: pointer;
        &:checked+label {
            &::before {
                // background-color: $green;
                border: 3px solid #fff;
            }
        }
        &:disabled+label {
            color: #AAAAAA;
            pointer-events: none;
        }
    }
    label {
        padding-left: 40px;
        position: relative;
        min-height: 20px;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            border-radius: 2px;
            background: rgba(255, 255, 255, 0.01);
            border: 1px solid rgba(255, 255, 255, 0.45);
            width: 20px;
            height: 20px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all .100s;
        }
    }
}


@media screen and (max-width: 576px){
    .fg{
        margin-bottom: 10px;
        input{
            height: 40px;
            border-radius: 5px;
            padding: 0 10px;
        }
        label{
            font-size: 12px;
        }
    }
}
