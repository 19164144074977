@import "../vars";
.catalog-card{
  background-color: $grayBg;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  &:hover{
    color: $black;
  }
  &__img{
    height: 340px;
    position: relative;
    overflow: hidden;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &__content{
    padding: 20px;
  }
  &__title{
    font-size: 16px;
    line-height: 161.8%;
  }
  &__price{
    margin-top: 2px;
    font-size: 14px;
    line-height: 161.8%;
    span{
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.lift-card{
  background-color: $grayBg;
  border-radius: 10px;
  overflow: hidden;
  &:hover{
    color: $black;
  }
  &__img{
    height: 465px;
    position: relative;
    overflow: hidden;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &__content{
    padding: 20px;
  }
  &__item{
    font-size: 16px;
    line-height: 117.8%;
    strong{
      font-weight: 700;
    }
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1200px){
  .catalog-card{
    &__img{
      //height: 280px;
    }
  }
}

@media screen and (max-width: 576px){
  .lift-card{
    &__img{
      height: 325px;
    }
    &__item{
      &:not(:last-child){
        margin-bottom: 5px;
      }
    }
  }
}
