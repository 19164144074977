@import "../vars";
.header{
  padding: 29px 0;
  background: #FFFFFF;
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  transition: all .3s;
  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    display: block;
    width: 197px;
    flex-shrink: 0;
    img{
      display: block;
      width: 100%;
    }
  }
  &__mob{
    display: flex;
    align-items: center;
  }
  &__btn{
    width: 175px;
    height: 42px;
    flex-shrink: 0;
  }
  &__phone{
    margin: 0 21px;
    font-weight: 700;
    font-size: 18px;
  }
  &__burger{
    display: none;
  }
  &--index:not(.header--active){
      backdrop-filter: unset;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      .header{
        &__logo{
          path{
            fill:#fff;
          }
        }
      }
  }
  &--fix{

  }

}

.nav{
  display: flex;
  align-items: center;
  &__link{
    font-weight: 600;
    font-size: 13px;
    &:not(:last-child){
      margin-right: 27px;
    }
  }
}


@media screen and (max-width: 1200px){
  .header{
    &__logo{
      width: 180px;
    }
    &__btn{
      width: 155px;
    }
    &__phone{
      margin: 0 12px;
      font-size: 16px;
    }
  }

  .nav{
    &__link{
      font-size: 12px;
      &:not(:last-child){
        margin-right: 12px;
      }
    }
  }
}

@media screen and (max-width: 992px){
  .header{
    padding: 16px 0;
    &__mob{
      position: fixed;
      z-index: 10;
      top: 65px;
      left: -100%;
      width: 100%;
      height: calc(100vh - 65px);
      background-color: #fff;
      padding: 20px 20px 70px;
      display: block;
      overflow-y: auto;
      transition: all .3s;
    }
    &__burger {
      z-index: 15;
      display: flex;
      align-items: center;
      width: 34px;
      height: 24px;
      position: relative;
      span {
        height: 3px;
        width: 100%;
        display: block;
        background-color: $primary;
        transition: all .225s;
        &:nth-child(1) {
          position: absolute;
          top: 0;
        }
        &:nth-child(3) {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

    }
    &__phone{
      font-size: 20px;
      margin: 0;
    }
    &--index{
      .header{
        &__burger{
          span{
            background-color: #fff;
          }
        }
      }
    }
    &--active{
      .header{
        &__burger{
          span{
            background-color: $primary;
            &:nth-child(1) {
              width: 100%;
              transform: translateY(11px) rotate(-45deg);
            }
            &:nth-child(2) {
              transform: scale(0);
            }
            &:nth-child(3) {
              width: 100%;
              transform: translateY(-10px) rotate(45deg);
            }
          }
          &~.menu {
            opacity: 1;
            pointer-events: unset;
          }

        }
        &__mob{
          left: 0;
        }
      }
    }
    &__btn{
      width: 175px;
      margin-top: 30px;
    }
  }

  .nav{
    align-items: unset;
    flex-direction: column;
    margin-bottom: 30px;
    &__link{
      font-size: 18px;
      &:not(:last-child){
        margin: 0 0 15px;
      }
    }
  }
}


@media screen and (max-width: 576px){
  .header{
    &__btn{
      width: 100%;
      height: 55px;
    }
  }
}
