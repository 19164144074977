@import "../vars";
.mbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0;
    &:focus {
        box-shadow: none;
    }
}

.mbtn-blue{
    color: #fff;
    background-color: $primary;
    border-radius: 9px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    &:hover{
        color: #fff;
        background-color: darken($primary, 10%);
    }
}
